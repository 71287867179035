import { iconName as _iconName, colour as _colour, IconName as _IconName, Colour as _Colour } from './types'
import Icon from './Icon'

import { ReactComponent as Support } from './svg/Support.svg'
import { ReactComponent as PlayShaded } from './svg/play-icon.svg'

export const IconType = {
  colour: _colour,
  iconName: _iconName
}

export type colour = _colour
export type iconName = _iconName
export type IconName = _IconName
export type Colour = _Colour

export {
  PlayShaded,
  Support
}

export default Icon
