// Known issue that tree shaking is not working with fontawesome (and typescript? babel?)
// Deep imports seems to be the solution for now
// cf. bug report: https://github.com/FortAwesome/Font-Awesome/issues/14552

import { faPlay as playOutline } from '@fortawesome/pro-light-svg-icons/faPlay'
import { faCalendarAlt as calendar } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt'
import { faFileCertificate as certificate } from '@fortawesome/pro-solid-svg-icons/faFileCertificate'
import { faCalendarAlt as calendarRegular } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import { faCalendar as calendarSolid } from '@fortawesome/pro-solid-svg-icons/faCalendar'
import { faClock as clock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faSquareFull as missing } from '@fortawesome/pro-regular-svg-icons/faSquareFull'
import { faAngleDown as angleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'
import { faAngleLeft as angleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft'
import { faAngleLeft as angleLeftRegular } from '@fortawesome/pro-regular-svg-icons/faAngleLeft'
import { faAngleRight as angleRightRegular } from '@fortawesome/pro-regular-svg-icons/faAngleRight'
import { faAngleRight as angleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight'
import { faAngleUp as angleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp'
import { faAngleUp as angleUpLight } from '@fortawesome/pro-regular-svg-icons/faAngleUp'
import { faBan as ban } from '@fortawesome/pro-solid-svg-icons/faBan'
import { faComments as chat } from '@fortawesome/pro-solid-svg-icons/faComments'
import { faExclamationTriangle as dangerCentral } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faExclamationCircle as dangerCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faExclamationTriangle as danger } from '@fortawesome/pro-duotone-svg-icons/faExclamationTriangle'
import { faInfo as info } from '@fortawesome/pro-solid-svg-icons/faInfo'
import { faInfoCircle as infoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faUser as user } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faUser as userOutlined } from '@fortawesome/pro-regular-svg-icons/faUser'
import { faUsers as users } from '@fortawesome/pro-solid-svg-icons/faUsers'
import { faUsers as usersLight } from '@fortawesome/pro-light-svg-icons/faUsers'
import { faUsersClass as usersClass } from '@fortawesome/pro-solid-svg-icons/faUsersClass'
import { faQuestion as question } from '@fortawesome/pro-solid-svg-icons/faQuestion'
import { faTimes as close } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faExternalLink as popOut } from '@fortawesome/pro-solid-svg-icons/faExternalLink'
import { faSpinner as spinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import { faChevronRight as chevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faChevronLeft as chevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronLeft as chevronLeftRegular } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faCheck as tick } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faCheck as tickRegular } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faWindowMinimize as minimise } from '@fortawesome/pro-solid-svg-icons/faWindowMinimize'
import { faLockOpenAlt as lockOpenAlt } from '@fortawesome/pro-solid-svg-icons/faLockOpenAlt'
import { faLockOpenAlt as lockOpenAltRegular } from '@fortawesome/pro-regular-svg-icons/faLockOpenAlt'
import { faCopy as copy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faCopy as copyRegular } from '@fortawesome/pro-regular-svg-icons/faCopy'
import { faPrint as print } from '@fortawesome/pro-solid-svg-icons/faPrint'
import { faDesktop as screen } from '@fortawesome/pro-light-svg-icons/faDesktop'
import { faImage as image } from '@fortawesome/pro-light-svg-icons/faImage'
import { faImage as imageSolid } from '@fortawesome/pro-solid-svg-icons/faImage'
import { faLock as lock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faMicrophone as microphone } from '@fortawesome/pro-solid-svg-icons/faMicrophone'
import { faMicrophoneSlash as microphoneSlash } from '@fortawesome/pro-solid-svg-icons/faMicrophoneSlash'
import { faVideo as video } from '@fortawesome/pro-solid-svg-icons/faVideo'
import { faVideoSlash as videoSlash } from '@fortawesome/pro-solid-svg-icons/faVideoSlash'
import { faPaperPlane as paperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane'
import { faPaperclip as paperClip } from '@fortawesome/pro-solid-svg-icons/faPaperclip'
import { faPause as pause } from '@fortawesome/pro-solid-svg-icons/faPause'
import { faPlay as play } from '@fortawesome/pro-solid-svg-icons/faPlay'
import { faPlayCircle as playCircle } from '@fortawesome/pro-solid-svg-icons/faPlayCircle'
import { faVolume as volume } from '@fortawesome/pro-solid-svg-icons/faVolume'
import { faScrubber as scrubber } from '@fortawesome/pro-solid-svg-icons/faScrubber'
import { faFlag as flag } from '@fortawesome/pro-solid-svg-icons/faFlag'
import { faTag as tag } from '@fortawesome/free-solid-svg-icons/faTag'
import { faCheckCircle as checkCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faSearch as search } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { faSearch as searchRegular } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faSearch as searchLight } from '@fortawesome/pro-light-svg-icons/faSearch'
import { faSearchPlus as searchPlus } from '@fortawesome/pro-solid-svg-icons/faSearchPlus'
import { faSearchMinus as searchMinus } from '@fortawesome/pro-solid-svg-icons/faSearchMinus'
import { faSortDown as sortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown'
import { faFile as file } from '@fortawesome/pro-regular-svg-icons/faFile'
import { faFileVideo as fileVideo } from '@fortawesome/pro-regular-svg-icons/faFileVideo'
import { faTrash as trash } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { faTrash as trashRegular } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faTrashAlt as trashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt'
import { faTrashAlt as trashAltRegular } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import { faTh as items } from '@fortawesome/free-solid-svg-icons/faTh'
import { faDownload as download } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faDownload as downloadRegular } from '@fortawesome/pro-regular-svg-icons/faDownload'
import { faUpload as upload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { faUpload as uploadRegular } from '@fortawesome/pro-regular-svg-icons/faUpload'
import { faReply as reply } from '@fortawesome/free-solid-svg-icons/faReply'
import { faReply as replyRegular } from '@fortawesome/pro-regular-svg-icons/faReply'
import { faEye as eye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faEye as eyeRegular } from '@fortawesome/pro-regular-svg-icons/faEye'
import { faEyeSlash as eyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faEyeSlash as eyeSlashRegular } from '@fortawesome/pro-regular-svg-icons/faEyeSlash'
import { faPhone as phone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faPhoneSlash as phoneSlash } from '@fortawesome/pro-solid-svg-icons/faPhoneSlash'
import { faLaptop as laptop } from '@fortawesome/pro-solid-svg-icons/faLaptop'
import { faPencil as pencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faPencil as pencilAltRegular } from '@fortawesome/pro-regular-svg-icons/faPencil'
import { faPencilAlt as pencil } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { faPencilAlt as pencilRegular } from '@fortawesome/pro-regular-svg-icons/faPencilAlt'
import { faPlus as plus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faPlus as plusRegular } from '@fortawesome/pro-regular-svg-icons/faPlus'
import { faPlusCircle as plusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle'
import { faPlusCircle as plusCircleRegular } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'
import { faTimesCircle as disabledInput } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faCaretLeft as caretLeft } from '@fortawesome/free-solid-svg-icons/faCaretLeft'
import { faCaretRight as caretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight'
import { faHeartbeat as status } from '@fortawesome/pro-solid-svg-icons/faHeartbeat'
import { faCaretUp as caretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp'
import { faCaretDown as caretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faCog as settings } from '@fortawesome/free-solid-svg-icons/faCog'
import { faFilter as filter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { faFilter as filterLight } from '@fortawesome/pro-light-svg-icons/faFilter'
import { faUndo as undo } from '@fortawesome/free-solid-svg-icons/faUndo'
import { faUndo as undoRegular } from '@fortawesome/pro-regular-svg-icons/faUndo'
import { faRedoAlt as redoAlt } from '@fortawesome/free-solid-svg-icons/faRedoAlt'
import { faRedoAlt as redoAltRegular } from '@fortawesome/pro-regular-svg-icons/faRedoAlt'
import { faIndent as indent } from '@fortawesome/free-solid-svg-icons/faIndent'
import { faIndent as indentLight } from '@fortawesome/pro-light-svg-icons/faIndent'
import { faUserPlus as userPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus'
import { faClipboardList as clipboardList } from '@fortawesome/pro-light-svg-icons/faClipboardList'
import { faClipboardList as faClipboardListSolid } from '@fortawesome/pro-solid-svg-icons/faClipboardList'
import { faSitemap as sitemap } from '@fortawesome/pro-light-svg-icons/faSitemap'
import { faEnvelope as envelopeLight } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faQuestionCircle as questionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faQuestionCircle as questionCircleRegular } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { faCommentAltExclamation as commentAltExclamation } from '@fortawesome/pro-solid-svg-icons/faCommentAltExclamation'
import { faSort as sort } from '@fortawesome/free-solid-svg-icons/faSort'
import { faUserCog as userCog } from '@fortawesome/pro-solid-svg-icons/faUserCog'
import { faChartBar as votes } from '@fortawesome/pro-solid-svg-icons/faChartBar'
import { faChartLine as chartLine } from '@fortawesome/pro-solid-svg-icons/faChartLine'
import { faFacebookF as facebook } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faFacebookSquare as facebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faTwitter as twitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faTwitterSquare as twitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare'
import { faLinkedinIn as linkedIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn'
import { faLinkedin as linkedInSquare } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faFilePdf as pdf } from '@fortawesome/pro-solid-svg-icons/faFilePdf'
import { faClock as clockSolid } from '@fortawesome/pro-solid-svg-icons/faClock'
import { faCheckCircle as tickCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faCheckCircle as tickCircleRegular } from '@fortawesome/pro-regular-svg-icons/faCheckCircle'
import { faEnvelope as envelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faGoogle as google } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faApple as apple } from '@fortawesome/free-brands-svg-icons/faApple'
import { faLink as link } from '@fortawesome/pro-solid-svg-icons/faLink'
import { faLink as linkRegular } from '@fortawesome/pro-regular-svg-icons/faLink'
import { faCloudUploadAlt as uploadCloud } from '@fortawesome/free-solid-svg-icons/faCloudUploadAlt'
import { faLink as linkLight } from '@fortawesome/pro-light-svg-icons/faLink'
import { faPaperclip as attachment } from '@fortawesome/pro-solid-svg-icons/faPaperclip'
import { faPaperclip as attachmentRegular } from '@fortawesome/pro-regular-svg-icons/faPaperclip'
import { faWindowRestore as windowRestore } from '@fortawesome/pro-solid-svg-icons/faWindowRestore'
import { faSync as sync } from '@fortawesome/pro-solid-svg-icons/faSync'
import { faSignOut as signOut } from '@fortawesome/pro-solid-svg-icons/faSignOut'
import { faCamera as camera } from '@fortawesome/pro-solid-svg-icons/faCamera'
import { faThumbtack as pin } from '@fortawesome/pro-solid-svg-icons/faThumbtack'
import { faDotCircle as dotCircle } from '@fortawesome/pro-solid-svg-icons/faDotCircle'
import { faSlash as slash } from '@fortawesome/pro-solid-svg-icons/faSlash'
import { layer, icon } from '@fortawesome/fontawesome-svg-core'
import { faStar as star } from '@fortawesome/pro-solid-svg-icons/faStar'
import { faStarHalfAlt as starHalfAlt } from '@fortawesome/pro-solid-svg-icons/faStarHalfAlt'
import { faPollH as pollH } from '@fortawesome/pro-regular-svg-icons/faPollH'
import { faEdit as edit } from '@fortawesome/free-solid-svg-icons/faEdit'
import { faHeading as heading } from '@fortawesome/free-solid-svg-icons/faHeading'
import { faTextHeight as textHeight } from '@fortawesome/free-solid-svg-icons/faTextHeight'
import { faTextWidth as textWidth } from '@fortawesome/free-solid-svg-icons/faTextWidth'
import { faComment as comment } from '@fortawesome/free-solid-svg-icons/faComment'
import { faList as list } from '@fortawesome/free-solid-svg-icons/faList'
import { faCheck as check } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faPlayCircle as playCircleOutline } from '@fortawesome/pro-regular-svg-icons/faPlayCircle'
import { faBars as bars } from '@fortawesome/pro-regular-svg-icons/faBars'
import { faChevronDown as chevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faChevronUp as chevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faTimes as closeLight } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faSitemap as sitemapSolid } from '@fortawesome/free-solid-svg-icons/faSitemap'
import { faUserEdit as userEdit } from '@fortawesome/pro-solid-svg-icons/faUserEdit'
import { faExpandWide as expandWide } from '@fortawesome/pro-solid-svg-icons/faExpandWide'
import { faCompressWide as compressWide } from '@fortawesome/pro-solid-svg-icons/faCompressWide'
import { faTh as th } from '@fortawesome/pro-solid-svg-icons/faTh'
import { faThLarge as thLarge } from '@fortawesome/pro-light-svg-icons/faThLarge'
import { faPodium as podium } from '@fortawesome/pro-solid-svg-icons/faPodium'
import { faWrench as wrench } from '@fortawesome/pro-solid-svg-icons/faWrench'
import { faCogs as cogs } from '@fortawesome/pro-solid-svg-icons/faCogs'
import { faWindows as windowsLogo } from '@fortawesome/free-brands-svg-icons/faWindows'
import { faSignalStream as signalStream } from '@fortawesome/pro-regular-svg-icons/faSignalStream'
import { faGlobe as globe } from '@fortawesome/pro-solid-svg-icons/faGlobe'
import { faGlobe as globeLight } from '@fortawesome/pro-light-svg-icons/faGlobe'
import { faGlobeAmericas as globeAmericas } from '@fortawesome/pro-solid-svg-icons/faGlobeAmericas'
import { faGlobeAfrica as globeAfrica } from '@fortawesome/pro-solid-svg-icons/faGlobeAfrica'
import { faGlobeAsia as globeAsia } from '@fortawesome/pro-solid-svg-icons/faGlobeAsia'
import { faArrowAltRight as arrowAltRight } from '@fortawesome/pro-solid-svg-icons/faArrowAltRight'
import { faHandPaper as hand } from '@fortawesome/pro-regular-svg-icons/faHandPaper'
import { faMountains as mountains } from '@fortawesome/pro-solid-svg-icons/faMountains'
import { faUsersClass as usersClassLight } from '@fortawesome/pro-light-svg-icons/faUsersClass'
import { faClipboard as clipboard } from '@fortawesome/pro-solid-svg-icons/faClipboard'
import { faPaintbrush as paintBrush } from '@fortawesome/pro-solid-svg-icons/faPaintbrush'
import { faImagePolaroid as imagePolaroid } from '@fortawesome/pro-solid-svg-icons/faImagePolaroid'
import { faRectanglePortrait as rectanglePortrait } from '@fortawesome/pro-solid-svg-icons/faRectanglePortrait'
import { faHandPaper as handPaper } from '@fortawesome/pro-solid-svg-icons/faHandPaper'
import { faHandPaper as handPaperRegular } from '@fortawesome/pro-regular-svg-icons/faHandPaper'
import { faTv as tv } from '@fortawesome/pro-solid-svg-icons/faTv'
import { faPlug as plugLight } from '@fortawesome/pro-light-svg-icons/faPlug'
import { faExclamationTriangle as warningLight } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faExclamationTriangle as warningSolid } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faLightbulbExclamation } from '@fortawesome/pro-light-svg-icons/faLightbulbExclamation'
import { faInfoCircle as infoCircleLight } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faLightbulbOn as faLightbulbOnLight } from '@fortawesome/pro-light-svg-icons/faLightbulbOn'
import { faXTwitter as xTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter'
import { faSquareXTwitter as xSquareTwitter } from '@fortawesome/free-brands-svg-icons/faSquareXTwitter'
import { faList as listRegular } from '@fortawesome/pro-regular-svg-icons/faList'
import { faRotateLeft } from '@fortawesome/pro-regular-svg-icons/faRotateLeft'
import { faCalendarClock as calendarClock } from '@fortawesome/pro-light-svg-icons/faCalendarClock'
import { faRotateRight as rotateRight } from '@fortawesome/pro-solid-svg-icons/faRotateRight'
import { faTriangleExclamation as triangleExclamationThin } from '@fortawesome/pro-thin-svg-icons/faTriangleExclamation'
import { faTriangleExclamation as triangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation'
import { faSliders as sliders } from '@fortawesome/pro-regular-svg-icons/faSliders'
import { faArrowUpWideShort as arrowUpWideShort } from '@fortawesome/pro-regular-svg-icons/faArrowUpWideShort'
import { faArrowDownWideShort as arrowDownWideShort } from '@fortawesome/pro-regular-svg-icons/faArrowDownWideShort'
import { faThumbsUp as thumbsUp } from '@fortawesome/free-solid-svg-icons'
import { faInboxIn as inboxIn } from '@fortawesome/pro-solid-svg-icons/faInboxIn'
import { faTriangleExclamation as triangleExclamationRegular } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { faAnglesLeft } from '@fortawesome/pro-light-svg-icons/faAnglesLeft'
import { faAnglesRight } from '@fortawesome/pro-light-svg-icons/faAnglesRight'
import {faArrowsRotate as rotate} from '@fortawesome/pro-solid-svg-icons/faArrowsRotate'

const pinWithSlash = layer((push) => {
  push(icon(slash, { transform: { y: 1, x: -2 }, mask: pin }))
  push(icon(slash, { transform: { x: -2 } }))
}).html[0]

export {
  angleDown,
  angleLeft,
  angleLeftRegular,
  angleRightRegular,
  angleRight,
  angleUp,
  apple,
  bars,
  ban,
  calendar,
  calendarSolid,
  camera,
  caretDown,
  caretLeft,
  caretRight,
  caretUp,
  certificate,
  chat,
  chevronDown,
  chevronLeft,
  chevronLeftRegular,
  chevronRight,
  chevronUp,
  clock,
  clockSolid,
  close,
  checkCircle,
  commentAltExclamation,
  compressWide,
  copy,
  danger,
  disabledInput,
  envelopeLight,
  dotCircle,
  download,
  envelope,
  expandWide,
  eye,
  eyeSlash,
  facebook,
  facebookSquare,
  file,
  filter,
  flag,
  globe,
  globeAmericas,
  globeAfrica,
  globeAsia,
  globeLight,
  google,
  image,
  imageSolid,
  indent,
  indentLight,
  info,
  infoCircle,
  items,
  laptop,
  link,
  linkLight,
  linkedIn,
  linkedInSquare,
  lock,
  microphone,
  microphoneSlash,
  sitemap,
  minimise,
  missing,
  paperPlane,
  paperClip,
  pause,
  pdf,
  pencil,
  pencilAlt,
  phone,
  clipboardList,
  faClipboardListSolid,
  phoneSlash,
  pin,
  pinWithSlash,
  play,
  playCircle,
  playOutline,
  playCircleOutline,
  plus,
  plusCircle,
  podium,
  popOut,
  pollH,
  print,
  question,
  questionCircle,
  screen,
  scrubber,
  search,
  settings,
  signalStream,
  signOut,
  sort,
  sortDown,
  spinner,
  userPlus,
  status,
  tag,
  sync,
  th,
  thLarge,
  thumbsUp,
  tick,
  trash,
  twitter,
  twitterSquare,
  undo,
  upload,
  uploadCloud,
  user,
  userOutlined,
  userCog,
  users,
  usersClass,
  video,
  videoSlash,
  volume,
  votes,
  windowsLogo,
  filterLight,
  searchLight,
  searchPlus,
  searchMinus,
  tickCircle,
  userEdit,
  usersLight,
  redoAlt,
  chartLine,
  attachment,
  attachmentRegular,
  windowRestore,
  angleUpLight,
  star,
  starHalfAlt,
  heading,
  textHeight,
  textWidth,
  comment,
  list,
  check,
  edit,
  trashAlt,
  reply,
  fileVideo,
  wrench,
  dangerCircle,
  closeLight,
  sitemapSolid,
  cogs,
  dangerCentral,
  lockOpenAlt,
  arrowAltRight,
  hand,
  calendarRegular,
  mountains,
  usersClassLight,
  clipboard,
  paintBrush,
  imagePolaroid,
  rectanglePortrait,
  handPaper,
  tv,
  plugLight,
  warningLight,
  warningSolid,
  faLightbulbExclamation,
  infoCircleLight,
  faLightbulbOnLight,
  xTwitter,
  xSquareTwitter,
  listRegular,
  faRotateLeft,
  calendarClock,
  rotateRight,
  triangleExclamationThin,
  triangleExclamation,
  sliders,
  arrowUpWideShort,
  arrowDownWideShort,
  lockOpenAltRegular,
  copyRegular,
  searchRegular,
  trashRegular,
  trashAltRegular,
  downloadRegular,
  tickRegular,
  uploadRegular,
  replyRegular,
  eyeRegular,
  eyeSlashRegular,
  pencilAltRegular,
  pencilRegular,
  plusRegular,
  plusCircleRegular,
  undoRegular,
  redoAltRegular,
  questionCircleRegular,
  tickCircleRegular,
  linkRegular,
  handPaperRegular,
  inboxIn,
  triangleExclamationRegular,
  faAnglesLeft,
  faAnglesRight,
  rotate,
}
