import React from 'react'
import * as SVG from './svg'
import { IconProps } from './types'

const SVGIcon: React.FC<IconProps> = (props): JSX.Element | null => {

  const SVGIcon = SVG[props.name]

  return typeof SVGIcon === 'undefined' ? null : <SVGIcon { ...props } />

}

export default SVGIcon
