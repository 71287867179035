export enum iconName {
  apple = 'apple',
  angleDown = 'angleDown',
  angleLeft = 'angleLeft',
  angleLeftRegular = 'angleLeftRegular',
  angleRightRegular = 'angleRightRegular',
  angleRight = 'angleRight',
  angleUp = 'angleUp',
  bars = 'bars',
  ban = 'ban',
  calendar = 'calendar',
  calendarSolid = 'calendarSolid',
  camera = 'camera',
  caretDown = 'caretDown',
  caretLeft = 'caretLeft',
  caretRight = 'caretRight',
  caretUp = 'caretUp',
  certificate = 'certificate',
  chat = 'chat',
  chevronDown = 'chevronDown',
  chevronLeft = 'chevronLeft',
  chevronLeftRegular = 'chevronLeftRegular',
  chevronRight = 'chevronRight',
  chevronUp = 'chevronUp',
  clock = 'clock',
  clockSolid = 'clockSolid',
  close = 'close',
  uploadCloud = 'uploadCloud',
  commentAltExclamation = 'commentAltExclamation',
  compressWide = 'compressWide',
  copy = 'copy',
  checkCircle = 'checkCircle',
  danger = 'danger',
  dangerCentral = 'dangerCentral',
  disabledInput = 'disabledInput',
  dotCircle = 'dotCircle',
  download = 'download',
  envelope = 'envelope',
  expandWide = 'expandWide',
  eye = 'eye',
  eyeSlash = 'eyeSlash',
  facebook = 'facebook',
  facebookSquare = 'facebookSquare',
  file = 'file',
  filter = 'filter',
  flag = 'flag',
  globe = 'globe',
  globeAmericas = 'globeAmericas',
  globeAfrica = 'globeAfrica',
  globeAsia = 'globeAsia',
  globeLight = 'globeLight',
  google = 'google',
  image = 'image',
  imageSolid = 'imageSolid',
  indent = 'indent',
  indentLight = 'indentLight',
  userPlus = 'userPlus',
  clipboardList = 'clipboardList',
  faClipboardListSolid = 'faClipboardListSolid',
  sitemap = 'sitemap',
  info = 'info',
  infoCircle = 'infoCircle',
  items = 'items',
  laptop = 'laptop',
  layoutGridSmall = 'layoutGridSmall',
  layoutGridActive = 'layoutGridActive',
  layoutGridInactive = 'layoutGridInactive',
  layoutFullSmall = 'layoutFullSmall',
  layoutFullActive = 'layoutFullActive',
  layoutFullInactive = 'layoutFullInactive',
  layoutSidebarSmall = 'layoutSidebarSmall',
  layoutSidebarActive = 'layoutSidebarActive',
  layoutSidebarInactive = 'layoutSidebarInactive',
  layoutSplitSmall = 'layoutSplitSmall',
  layoutSplitActive = 'layoutSplitActive',
  layoutSplitInactive = 'layoutSplitInactive',
  link = 'link',
  linkLight = 'linkLight',
  linkedIn = 'linkedIn',
  linkedInSquare = 'linkedInSquare',
  lock = 'lock',
  microphone = 'microphone',
  microphoneSlash = 'microphoneSlash',
  envelopeLight = 'envelopeLight',
  minimise = 'minimise',
  missing = 'missing',
  paperPlane = 'paperPlane',
  paperClip = 'paperClip',
  pause = 'pause',
  pdf = 'pdf',
  imagePolaroid = 'imagePolaroid',
  pencil = 'pencil',
  pencilAlt = 'pencilAlt',
  phone = 'phone',
  phoneSlash = 'phoneSlash',
  pin = 'pin',
  pinWithSlash = 'pinWithSlash',
  play = 'play',
  playCircle = 'playCircle',
  playCircleOutline = 'playCircleOutline',
  playOutline = 'playOutline',
  playShaded = 'playShaded',
  plus = 'plus',
  plusCircle = 'plusCircle',
  popOut = 'popOut',
  print = 'print',
  question = 'question',
  questionCircle = 'questionCircle',
  screen = 'screen',
  scrubber = 'scrubber',
  search = 'search',
  searchPlus = 'searchPlus',
  searchMinus = 'searchMinus',
  settings = 'settings',
  signalStream = 'signalStream',
  signOut = 'signOut',
  sort = 'sort',
  sortDown = 'sortDown',
  spinner = 'spinner',
  status = 'status',
  stop = 'stop',
  support = 'support',
  tag = 'tag',
  sync = 'sync',
  tick = 'tick',
  trash = 'trash',
  twitter = 'twitter',
  twitterSquare = 'twitterSquare',
  undo = 'undo',
  upload = 'upload',
  user = 'user',
  userOutlined = 'userOutlined',
  users = 'users',
  usersClass = 'usersClass',
  userCog = 'userCog',
  userEdit = 'userEdit',
  video = 'video',
  videoSlash = 'videoSlash',
  volume = 'volume',
  votes = 'votes',
  facebookIcon = 'facebookIcon',
  linkedinIcon = 'linkedinIcon',
  twitterIcon = 'twitterIcon',
  filterLight = 'filterLight',
  searchLight = 'searchLight',
  supportIcon = 'supportIcon',
  tickCircle = 'tickCircle',
  usersLight = 'usersLight',
  redoAlt = 'redoAlt',
  chartLine = 'chartLine',
  attachment = 'attachment',
  attachmentRegular = 'attachmentRegular',
  windowRestore = 'windowRestore',
  windowsLogo = 'windowsLogo',
  angleUpLight = 'angleUpLight',
  star = 'star',
  starHalfAlt = 'starHalfAlt',
  pollH = 'pollH',
  heading = 'heading',
  textHeight = 'textHeight',
  textWidth = 'textWidth',
  th = 'th',
  thLarge = 'thLarge',
  comment = 'comment',
  list = 'list',
  check = 'check',
  edit = 'edit',
  trashAlt = 'trashAlt',
  reply = "reply",
  fileVideo = "fileVideo",
  dangerCircle = 'dangerCircle',
  closeLight = 'closeLight',
  sitemapSolid = 'sitemapSolid',
  podium = 'podium',
  wrench = 'wrench',
  cogs = 'cogs',
  lockOpenAlt = 'lockOpenAlt',
  arrowAltRight = 'arrowAltRight',
  hand = 'hand',
  calendarRegular = 'calendarRegular',
  mountains = 'mountains',
  usersClassLight = 'usersClassLight',
  clipboard = 'clipboard',
  paintBrush = 'paintBrush',
  rectanglePortrait = 'rectanglePortrait',
  handPaper = 'handPaper',
  tv = 'tv',
  plugLight = 'plugLight',
  warningLight = 'warningLight',
  warningSolid = 'warningSolid',
  faLightbulbExclamation = 'faLightbulbExclamation',
  infoCircleLight = 'infoCircleLight',
  faLightbulbOnLight = 'faLightbulbOnLight',
  xTwitter = 'xTwitter',
  xSquareTwitter = 'xSquareTwitter',
  listRegular = 'listRegular',
  faRotateLeft = 'faRotateLeft',
  calendarClock = 'calendarClock',
  rotateRight = 'rotateRight',
  triangleExclamationThin = 'triangleExclamationThin',
  triangleExclamation = 'triangleExclamation',
  sliders = 'sliders',
  arrowUpWideShort = 'arrowUpWideShort',
  arrowDownWideShort = 'arrowDownWideShort',
  thumbsUp = 'thumbsUp',
  lockOpenAltRegular = 'lockOpenAltRegular',
  copyRegular = 'copyRegular',
  searchRegular = 'searchRegular',
  trashRegular = 'trashRegular',
  trashAltRegular = 'trashAltRegular',
  downloadRegular = 'downloadRegular',
  tickRegular = 'tickRegular',
  uploadRegular = 'uploadRegular',
  replyRegular = 'replyRegular',
  eyeRegular = 'eyeRegular',
  eyeSlashRegular = 'eyeSlashRegular',
  pencilAltRegular = 'pencilAltRegular',
  pencilRegular = 'pencilRegular',
  plusRegular = 'plusRegular',
  plusCircleRegular = 'plusCircleRegular',
  undoRegular = 'undoRegular',
  redoAltRegular = 'redoAltRegular',
  questionCircleRegular = 'questionCircleRegular',
  tickCircleRegular = 'tickCircleRegular',
  linkRegular = 'linkRegular',
  handPaperRegular = 'handPaperRegular',
  inboxIn = 'inboxIn',
  triangleExclamationRegular = 'triangleExclamationRegular',
  faAnglesLeft = 'faAnglesLeft',
  faAnglesRight = 'faAnglesRight',
  rotate = 'faArrowsRotate'
}

export enum colour {
  brightTALK = 'brightTALK',
  brightTALKDark = 'brightTALK-dark',
  danger = 'danger',
  darkGrey = 'dark-grey',
  darkDanger = 'dark-danger',
  default = 'secondary',
  lightGrey = 'light-grey',
  link = 'link',
  success = 'success',
  londonGrey = 'london-grey',
  pastelGreen = 'pastel-green',
  primary = 'primary',
  secondary = 'secondary',
  task = 'task',
  tertiary = 'tertiary',
  white = 'white',
  lightGreen = 'light-green',
  lighterGreen = 'lighter-green',
  green = 'green',
  contentBlue = 'content-blue',
  purple = 'purple',
  black = 'black',
  none = 'none',
  bright = 'bright',
  skyBlue = 'sky-blue',
  adBtn = 'ad-btn',
  promoteBtn = 'promote-btn',
  darkOrange = 'dark-orange',
  neutral = 'neutral',
  indigo = 'indigo'
}

export type IconName = keyof typeof iconName
export type Colour = keyof typeof colour

export interface IconProps {
  name: iconName | IconName
  className?: HTMLDivElement['className']
  colour?: colour | Colour
  dataBdd?: string
  ref?: any
  style?: any
  id?: string
  title?: string
  spin?: boolean
  tabIndex?: number
}
