import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import SVGIcon from './SVGIcon'
import has from 'lodash/has'
import getColorClass from '@bt-react/libs/getColorClass'
import * as font from './IconResolver'
import * as SVG from './svg'
import { IconProps, iconName, IconName } from './types'

function getIcon (name: iconName | IconName): IconDefinition {

  return (font[name] || font[iconName.missing])

}

/** Determines if the SVG is in the custom SVG folder */
function isSVGIcon (name: iconName | IconName): boolean {

  return has(SVG, name)

}

/** Creates an instance of a Font Awesome icon */
const Icon: React.FC<IconProps> = (props) => {

  const {
    name = '',
    className = '',
    colour = '',
    spin = false
  } = props

  if (name === '') return null

  const attrs = name === iconName.spinner || spin ? { spin: true } : {}

  const iconClasses = classnames(
    getColorClass(colour, 'color'),
    className
  )

  if (isSVGIcon(name)) {
    return <SVGIcon
      name={name}
      className={iconClasses}
      data-bdd={props.dataBdd ? props.dataBdd : `item-${name}`}
      { ...attrs }
      style={ props.style ? props.style : {} }
      {...(props.id ? {id: props.id} : {})}
      title={props.title}
    />

  }

  if (name === 'pinWithSlash') {

    const Y: string = getIcon(name) as unknown as string
    const layeredStyle = { ...props.style, justifyContent: 'center', alignItems: 'center', display: 'flex' }

    return <span
      dangerouslySetInnerHTML={{__html: Y}}
      className={iconClasses}
      data-bdd={ props.dataBdd ? props.dataBdd : `item-${name}` }
      { ...attrs }
      style={ layeredStyle }
      {...(props.id ? {id: props.id} : {})}
      title={props.title}
    />

  }

  return <FontAwesomeIcon
    icon={ getIcon(name) }
    className={iconClasses}
    data-bdd={ props.dataBdd ? props.dataBdd : `item-${name}` }
    { ...attrs }
    style={ props.style ? props.style : {} }
    {...(props.id ? {id: props.id} : {})}
    title={props.title}
    {...(props.tabIndex !== undefined ? {tabIndex: props.tabIndex} : {})}
  />

}

export default Icon
