export enum Color {
  brightTALK = 'brightTALK',
  brightTALKDark = 'brightTALK-dark',
  brightTALKLight = 'brightTALK-light',
  danger = 'danger',
  darkDanger = 'dark-danger',
  darkGrey = 'dark-grey',
  default = 'secondary',
  pastelGreen = 'pastel-green',
  lightGrey = 'light-grey',
  link = 'link',
  londonGrey = 'london-grey',
  primary = 'primary',
  secondary = 'secondary',
  task = 'task',
  tertiary = 'tertiary',
  white = 'white',
  lightGreen = 'light-green',
  green = 'green',
  contentBlue = 'content-blue',
  purple = 'purple',
  black = 'black',
  none = 'none',
  bright = 'bright',
  skyBlue = 'sky-blue',
  adBtn = 'ad-btn',
  promoteBtn = 'promote-btn',
  darkOrange = 'dark-orange',
  neutral = 'neutral',
  indigo = 'indigo'
}
