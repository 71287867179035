import { ReactComponent as support } from './Support.svg'
import { ReactComponent as playShaded } from './play-icon.svg'
import { ReactComponent as facebookIcon } from './facebook-icon-small.svg'
import { ReactComponent as linkedinIcon } from './linkedin-icon-small.svg'
import { ReactComponent as twitterIcon } from './twitter-icon-small.svg'
import { ReactComponent as supportIcon } from './support-icon.svg'
import { ReactComponent as layoutGridSmall } from './layouts/grid-icon-small.svg'
import { ReactComponent as layoutGridActive } from './layouts/grid-icon-large-active.svg'
import { ReactComponent as layoutGridInactive } from './layouts/grid-icon-large-inactive.svg'
import { ReactComponent as layoutFullSmall } from './layouts/full-icon-small.svg'
import { ReactComponent as layoutFullActive } from './layouts/full-icon-large-active.svg'
import { ReactComponent as layoutFullInactive } from './layouts/full-icon-large-inactive.svg'
import { ReactComponent as layoutSidebarSmall } from './layouts/sidebar-icon-small.svg'
import { ReactComponent as layoutSidebarActive } from './layouts/sidebar-icon-large-active.svg'
import { ReactComponent as layoutSidebarInactive } from './layouts/sidebar-icon-large-inactive.svg'
import { ReactComponent as layoutSplitSmall } from './layouts/split-icon-small.svg'
import { ReactComponent as layoutSplitActive } from './layouts/split-icon-large-active.svg'
import { ReactComponent as layoutSplitInactive } from './layouts/split-icon-large-inactive.svg'

export {
  playShaded,
  support,
  facebookIcon,
  linkedinIcon,
  twitterIcon,
  supportIcon,
  layoutGridSmall,
  layoutGridActive,
  layoutGridInactive,
  layoutFullSmall,
  layoutFullActive,
  layoutFullInactive,
  layoutSidebarSmall,
  layoutSidebarActive,
  layoutSidebarInactive,
  layoutSplitSmall,
  layoutSplitActive,
  layoutSplitInactive
}
