import { Color } from './types'
import styles from './getColorClass.module.scss'
export type Element = 'color' | 'backgroundColor' | 'button'

function getColorClass (color = '', element: Element = 'color'): string {

  let prefix = ''

  switch (element) {

    case 'button':
      prefix = 'button-'
      break
    case 'backgroundColor':
      prefix = 'background-'
      break
    case 'color': default:
      prefix = 'text-'
      break

  }
  
  switch (color) {
    
    case Color.task:
      return styles[`${prefix}task`]
    case Color.brightTALK:
      return styles[`${prefix}brighttalk`]
    case Color.white:
      return styles[`${prefix}white`]
    case Color.primary:
      return styles[`${prefix}primary-bt`]
    case Color.danger:
      return styles[`${prefix}danger`]
    case Color.darkDanger:
      return styles[`${prefix}dark-danger`]
    case Color.londonGrey:
      return styles[`${prefix}london-grey`]
    case Color.brightTALKDark:
      return styles[`${prefix}brighttalk-dark`]
    case Color.tertiary:
      return styles[`${prefix}tertiary`]
    case Color.darkGrey:
      return styles[`${prefix}dark-grey`]
    case Color.pastelGreen:
      return styles[`${prefix}pastel-green`]
    case Color.lightGrey:
      return styles[`${prefix}light-grey`]
    case Color.lightGreen:
      return styles[`${prefix}light-green`]
    case Color.green:
      return styles[`${prefix}green`]
    case Color.contentBlue:
      return styles[`${prefix}content-blue`]
    case Color.purple:
      return styles[`${prefix}purple`]
    case Color.black:
      return styles[`${prefix}black`]
    case Color.bright:
      return styles[`${prefix}bright`]
    case Color.skyBlue:
      return styles[`${prefix}sky-blue`]
    case Color.adBtn:
      return styles[`${prefix}ad-btn`]
    case Color.promoteBtn:
      return styles[`${prefix}promote-btn`]
    case Color.darkOrange:
      return styles[`${prefix}dark-orange`]
    case Color.neutral:
      return styles[`${prefix}neutral`]
    case Color.indigo:
      return styles[`${prefix}indigo`]
    case Color.none:
      return ''
    case Color.secondary: default:
      return styles[`${prefix}secondary`]
  }

}

export default getColorClass
